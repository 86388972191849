import React from 'react';
import { graphql } from 'gatsby';
import Seo from '../components/seo';
import Layout from '../components/layout';
import ReactMarkdown from 'react-markdown';

import Hero from '../components/rows/hero/index';
import Banner from '../images/hero-images/services-bio-hero.jpg';
import { Container, Row, Col } from 'react-grid-system';

export default function conditionTreatmentsArticle({ data }) {
  let ct = data.strapiConditionTreatments;

  return (
    <Layout>
      <Seo title={ct.title} />
      <Hero title={ct.title} ct banner={Banner} />
      <Container className="mb-20">
        <Row>
          <Col md={8} className="mx-auto">
            <div className="text-center mb-10">
              <h2>{ct.title}</h2>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={8} className="mx-auto">
            {ct.condition_acc.map((acc) => (
              <div key={acc.id} className="my-8 ct-row">
                {acc.title ? (
                  <h5 className="text-red-300">{acc.title}</h5>
                ) : null}
                <ReactMarkdown>{acc.description}</ReactMarkdown>
              </div>
            ))}
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}

export const query = graphql`
  query ConditionTemplate($slug: String!) {
    strapiConditionTreatments(slug: { eq: $slug }) {
      id
      title
      slug
      condition_acc {
        id
        title
        description
      }
      pdf_doc {
        localFile {
          publicURL
        }
      }
    }
  }
`;
